import React,{useEffect,useState} from 'react';
import InputField from '../../../../components/Yard/InputField/InputField';
import {PlusCircleOutlined,SaveFilled,EditFilled,DeleteOutlined,UndoOutlined,CloseOutlined} from '@ant-design/icons';
import { Card, Table,Select, Button, InputNumber,Input, Divider, Row, Col, message,Spin } from 'antd';
import API from '../../../../api';
import { connect } from 'react-redux';

import { TextCapitalize } from '../../../../utils/helper';
const {Option} = Select;
const ServiceSelect = (props) => {
  const {fetchServiceDetailsPost,addSubTicket,editQuantity,deletePerforma,undoPerforma}=API
  const [btnDisable, setBtnDisable] = useState(false);
  const [invoiceData,setInvoiceData]=useState(null)
  const [addBtnDisable, setaddBtnDisable] = useState(false);
  const [itemAddDiable, setItemAddDisable] = useState(true);
  const [service,setService]=useState([])
  const [subService,setSubservice]=useState([])

  useEffect(()=>{
      props.ticketDetailsData&&props.ticketDetailsData.map((item)=>{
        setProformaData(item.sub_tickets)
      })
  },[props.ticketDetailsData])
  const setProformaData=(res)=>{
    const _data=res
    const data=localStorage.getItem ('data')
    let jsonParse = JSON.parse (data);
    const invoiceArr=[]
  
          if(_data.length===0){
              invoiceArr.push({services:{param_description:''},estimate_price:''})
          }else{
            _data.map((item,i)=>{
              const serviceMstid= subService.filter((id)=>id.id===item.service_mst_id)
              const filter= jsonParse.TabParams.filter((ele)=>(item.service_id===ele.param_value)&&ele.parent_id===props.parent_id)
              if(filter.length!==0){
              invoiceArr.push({...item,key:i+1,service:TextCapitalize(serviceMstid[0]?.serviceName),quantity:item.quantity,edit:false})
              }
            })
          }
          setaddBtnDisable(false)
          setInvoiceData(invoiceArr)
  }
  const addMore=()=>{
    setBtnDisable(true);
    setaddBtnDisable(true)
    setItemAddDisable(true)
    const arr=[...invoiceData];
    arr.push({services:{param_description:''},estimate_price:''})
    setInvoiceData(arr)
}

useEffect(()=>{
  setaddBtnDisable(true)
},[])

useEffect(()=>{
  filterData(props.serviceData)
},[props.serviceData])

useEffect(()=>{
  props.ticketDetailsData&&props.ticketDetailsData.map((item)=>{
    setProformaData(item.sub_tickets)
  })
},[props.ticketDetailsData])
const filterData=(val)=>{
  let service=[]
  let subService=[]
  
  val.forEach(function(item){

    let _service = service.filter(x => (x.value == item.service_id));
    if(_service.length <= 0&&item.service_group_id===props.parent_id){
      service.push({parent_id:item.service_group_id, name:item['service_name.param_description'],value:item.service_id});
    }
    if(item['sub_service_name.param_description']){
      subService.push({parent_id:item.service_id,placeholder:'Select Sub Service', serviceName:`${item['service_name.param_description']} (${item['sub_service_name.param_description']})`, name:`${item['sub_service_name.param_description']}`,id:item.id,value:item.sub_service_id,tax:item.tax,price:item.price});
    }else{
      subService.push({parent_id:item.service_id,placeholder:'Category',serviceName:`${item['service_name.param_description']}`, name:`${item.category}`,id:item.id,tax:item.tax,price:item.price});
    }
    
  })
  setService(service)
  setSubservice(subService)
}
const onSelectService=(e,i,_item)=>{
  const arr=[...invoiceData]
  let child=[]
  subService.map((ele)=>{
      if(e===ele.parent_id){
        child.push(ele)
    }
  })

const ids = new Set(arr.map(({ service_mst_id }) => service_mst_id));
let  selectedRows = child.filter(({ id }) => !ids.has(id));
  setItemAddDisable(true);
  arr.map((item,index)=>{
      if(index===i){
            item.subService=selectedRows
            item.service_id=e;
            item.id=''
            item.sub_service_id="";
            // item.estimate_price = ele.price
  }
  })
  setInvoiceData(arr)
}
const onSelectSubService=(e,i,_item)=>{
  const arr=[...invoiceData]
  setItemAddDisable(false);
  arr.map((item,index)=>{
      if(index===i){
      item.subService&&item.subService.map((ele)=>{
          if(e===ele.id){
            item.sub_service_id=ele.value
            item.id=e;
            item.estimate_price = ele.price
            item.tax = ele.tax
          }
      })
  }
  })
  setInvoiceData(arr)
}
const AddSubTicket=(i,item)=>{
  setaddBtnDisable(true)
  setItemAddDisable(true)
  const serviceMstid= subService.filter((id)=>id.id===item.id)
  const arr=[...invoiceData];
  let ticketData={ 
    "id":item.id,
  "ticket_id":props.data?.ticket_id,
  "status":props.data.ticket_status?.param_value,
  "price":item.estimate_price,
  "tax":item.tax,
  "service_id":item.service_id,
  "quantity":item.quantity,
  "coordinates":[0.00,	0.00]}
  if(item.sub_service_id){
    ticketData= {...ticketData, "sub_service_id":item.sub_service_id}
  }
  addSubTicket(ticketData).then((res)=>{
      const data =res.data.data;
      setaddBtnDisable(false)
      setItemAddDisable(false)
      if(res.data.success){
        message.success(res.data.message)
        arr.splice(i,1,{...data,service:serviceMstid[0]?.serviceName})
        checkIncomplete(arr)
        setInvoiceData(arr)
      }else{
        message.error(res.data.message)
      }
}).catch((err)=>{
  setaddBtnDisable(false)
  setItemAddDisable(false)
})
}
const checkIncomplete=(arr)=>{
  const filter =arr.filter((item)=>!item.ticket_id)  
 if(filter.length===0){
      setBtnDisable(false)
      setaddBtnDisable(false)
 }else{
  setBtnDisable(true)
  setaddBtnDisable(true)
 }
}
const deleteTicket =(i,item,ticket_id)=>{
  const arr=[...invoiceData];
  if(ticket_id){
  deletePerforma(ticket_id,{coordinates:[0.00,0.00]}).then((res)=>{
    item.deleted=true;
      checkIncomplete(arr)
      setInvoiceData(arr)
  }).catch((err)=>{
      console.log(err)
  })
}else{
      arr.splice(i,1)
      checkIncomplete(arr)
      setInvoiceData(arr)
  }
}
const editTicket=(i,item,ticket_id)=>{
  const arr=[...invoiceData];
  arr.map((_item,index)=>{
    if(index===i){
      _item.edit=true
    }
  })
  setInvoiceData(arr)
}
const updateTicket=(i,item,ticket_id)=>{
      const param={id:ticket_id,quantity:item.quantity}
      editQuantity(param).then((res)=>{
        const arr=[...invoiceData];
        arr.map((_item,index)=>{
          if(index===i){
        if(res.data.success){
          _item.edit=false
          message.success({ content: res.data.message, duration: 2 });
        }else{
          message.error({ content: res.data.message, duration: 2 });
        }
      }
    })
    setInvoiceData(arr)
      }).catch((err)=>{
        message.error({ content: `${err?.response?.data.message}`, duration: 2 });
      })

    
 

}
const undoTicket =(i,item,ticket_id)=>{
  const arr=[...invoiceData];
  if(ticket_id){
  const arr=[...invoiceData];
undoPerforma(ticket_id,{coordinates:[0.00,0.00]}).then((res)=>{
item.deleted=false;
setInvoiceData(arr)
  })}else{
      arr.splice(i,1)
      setInvoiceData(arr)
  }
}
const subServicePlaceHolder=(value)=>{
  const placeholder=value&&value.map((item)=>item.placeholder)
  if(placeholder[0]){
    return placeholder[0]
  }else{
   return "Select Sub Service"
  }
 
}
const onChangeQuantity=(e,i)=>{
 const _invoiceData= [...invoiceData]
  _invoiceData.map((item,index)=>{
    if(index===i){
      item.quantity=e
    }
  })
setInvoiceData(_invoiceData)
}
  return (
    <div className="amount">
     <div className="d-flex" style={{ justifyContent:"space-between"}}>   
     <h3 style={{textTransform:'uppercase'}}>{props.name}</h3>
     <Button disabled={addBtnDisable} onClick={()=>addMore()}>+Add Service</Button>                  
       </div> 
      <div className="amount_name">
      {invoiceData&&invoiceData.length!==0?
    <table id="services" style={{width:'100%'}}>
    <tr style={{height:32}}>
        <th style={{width:'60%'}}>ITEM</th>
        <th style={{width:'10%',textAlign:'center',}}>QUANTITY</th>
        <th style={{width:'30%',textAlign:'end'}}></th>
    </tr>
    {invoiceData.map((item,i)=><tr style={{height:64}}>
        <td>{!item.ticket_id?<div style={{display:'flex',marginRight:"5px"}}>
                  <Select
                      style={{marginLeft:10}}
                      value={item.service_id}
                        placeholder="Select Service"
                        onChange={(e) =>onSelectService(e,i,item)}
                      >
                             <Option value="" disabled>
                              Select Service
                            </Option>
                        {service&&service.map (ele =>(
                            <Option value={ele.value}>
                              {ele.name}
                            </Option>
                          ))}
                      </Select>

                      {item?.subService&&<Select
                      style={{marginLeft:10}}
                      value={item.id}
                        placeholder={subServicePlaceHolder(item.subService)}
                        onChange={(e) =>onSelectSubService(e,i,item)}
                      >
                         <Option value="" disabled >
                         {subServicePlaceHolder(item.subService)}
                            </Option>
                        {item.subService.map (ele =>(
                            <Option value={ele.id}>
                              {ele.name}
                            </Option>
                          ))}
                      </Select>} 
                      
                      
                      </div>:item.service}</td>
        <td style={{textAlign:'center'}}>{(!item.ticket_id||item.edit)?<InputNumber min={1}  onChange={(e)=>onChangeQuantity(e,i)} value={item.quantity}/>:item.quantity}</td>
            <td>
                <div style={{display:'flex',justifyContent:'flex-end',marginLeft:"5px"}}>
                {((item.deleted!==undefined||item.deleted!==false)&& !item.ticket_id)&&
                <Button className="ant-button-custom" style={{marginRight:10,fontSize:18}}  disabled={itemAddDiable} type="primary" onClick={()=>AddSubTicket(i,item)}><PlusCircleOutlined/></Button>
              }
              {!item.deleted&&item.ticket_id&& <> { !item.edit?
                <Button className="ant-button-custom" type="primary" style={{fontSize:18,marginRight:10}} onClick={()=>editTicket(i,item,item.ticket_id)}><EditFilled/></Button>:
                <Button className="ant-button-custom" type="primary" style={{fontSize:18,marginRight:10}} onClick={()=>updateTicket(i,item,item.ticket_id)}><SaveFilled/></Button>
                }</>}
            {item.deleted==true?
            <Button className="ant-button-custom" type="primary" style={{fontSize:18}} onClick={()=>undoTicket(i,item,item.ticket_id)}><UndoOutlined/></Button>
            :
            
            <Button className="ant-button-custom" type="primary" style={{fontSize:18}} danger onClick={()=>deleteTicket(i,item,item.ticket_id)}><DeleteOutlined/></Button>  
              
 }
 </div>
            </td>
    </tr>)}        
</table>  :<div style={{height:250}}></div>}
        </div>
    </div>
  );
};

export default ServiceSelect;

